
<template>
<v-app>
  <v-navigation-drawer
      v-if="loading == false"
      v-model="drawer"
      fixed
      temporary
    >
        <v-subheader class="mt-3 grey--text text--darken-1">Menu</v-subheader>
        <v-card
            tile
            flat
          >
            <v-list-item v-for="item in items2" :key="item.text" :href="item.id">
              <v-list-item-content>
                <v-list-item-title>{{item.text}}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
    </v-navigation-drawer>
    <v-app-bar
      fixed
      v-if="loading == false"
      color="cyan darken-4"
      dark
    >
      <!--<v-toolbar-title>Page title</v-toolbar-title>-->
      <a v-bind:href="'http://cheeseteaco.com/'">
          <img :src="img_url + '1qPlc4i5dB0Or5RsYQ3d5hqKI0iTQFoWX&sz=w170-rw'" width="45px">
      </a>
      
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
    </v-app-bar>
    <template v-if="loading == true">
      <v-row class="fill-height"
      align="center"
      justify="center"
    >
      <v-progress-circular indeterminate color="cyan darken-4" size='120'>
        <v-img src="https://drive.google.com/thumbnail?id=1qPlc4i5dB0Or5RsYQ3d5hqKI0iTQFoWX&sz=w80-rw">
        </v-img>
      </v-progress-circular>
      </v-row>
    </template>
    <v-container v-if="loading == false">
    <v-layout row wrap class="mt-12">
        <v-flex xs12 id="beranda"><v-img :src="'https://drive.google.com/thumbnail?id=1bL86_L7exhEPyJn9P8nVwaVWfBxIITQb&sz=w' + width  + '-rw'" alt="Responsive image"></v-img></v-flex>
        <v-flex xs12 id=""><v-img :src="'https://drive.google.com/thumbnail?id=1EGTJn2qVvKXccF0nywEmBT8JgwNXr3fN&sz=w' + width  + '-rw'" alt="Responsive image"></v-img></v-flex>
         <v-flex xs12 id=""><v-img :src="'https://drive.google.com/thumbnail?id=14HeJOcnLNwuOMxZ-BTTRNE_-hVaxKT3f&sz=w' + width  + '-rw'" alt="Responsive image"></v-img></v-flex>

        <v-flex xs12 id="">
            <v-carousel :show-arrows="true" :height="600"  hide-delimiters>
                <v-carousel-item
                  v-for="(item,i) in items1"
                  :key="i"
                  :src="img_url + item.image + '&sz=w0' + 1200 + '-rw'"
                ></v-carousel-item>
              </v-carousel>
        </v-flex>

  <v-flex xs12 id="kemitraan" class="pt-1 pb-1">
  <v-card>  
      <v-toolbar
        flat
        color="primary"
        dark
      >
        <v-toolbar-title>
          <h2 class="text-center pa-0"> PILIHAN PAKET KEMIRAAN</h2>
        </v-toolbar-title>
      </v-toolbar>

 <v-tabs-items v-model="tab">
      <v-tab-item
        value="tab-1"
      >
        <v-card flat>
          <v-card-text>
            <v-layout row wrap class="pa-3">
              <img :src="img_url + '1VR3gAST2RYUbmRGqFim1Zgqyvx_LH-2c&sz=w0'" 
              width="100%" 
              height="100%"
              @click.stop="dialogimg = true"
              > 
              <v-dialog
                v-model="dialogimg"
                max-width="85%"
              >
      <v-card v-model="dialogimg">
          <img :src="img_url + '1VR3gAST2RYUbmRGqFim1Zgqyvx_LH-2c&sz=w0'" width="100%" height="100%">   
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialogimg = false"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
          <v-flex xs4>
                    <img :src="img_url + '1VR3gAST2RYUbmRGqFim1Zgqyvx_LH-2c&sz=w0'" 
                                width="100%" 
                                height="100%"
                                @click.stop="dialogimg1 = true"
                                > 
                                <v-dialog
                        v-model="dialogimg1"
                        max-width="85%"
                      >
                          <v-card v-model="dialogimg1">
                            <img :src="img_url + '1VR3gAST2RYUbmRGqFim1Zgqyvx_LH-2c&sz=w0'" width="100%" height="100%">   
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="dialogimg1 = false"
                            >
                              Tutup
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
          </v-flex>
        </v-layout>
            <p>
              <h2 class="text-center">PAKET TANPA BOOTH</h2>
                <br>
                <p class="text-justify">
                  Bagi kamu yang ingin memulai usaha tapi minim modal kami rekomendasikan untuk mengambil paket ini.
                  <br><span>Fitur Keunggulan :</span>
                  <ul>
                      <li>Sign Board</li>
                      <li>Seragam </li>
                      <li>Alat Pendukung</li>
                      <li>Media Promosi</li>
                      <li>Bahan Baku Awal</li>
                      <li>Cup Sealer</li>
                  </ul>   
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

<v-tab-item
        value="tab-2"
      >
        <v-card flat>
          <v-card-text>
            <v-layout row wrap class="pa-3">
              <img :src="img_url + '11EcWNPiOhHr8zo68FK5zpo_ZlxJmTKG1&sz=w0'" 
              width="100%" 
              height="100%"
              @click.stop="dialogimg = true"
              > 
              <v-dialog
                v-model="dialogimg"
                max-width="85%"
              >
      <v-card v-model="dialogimg">
          <img :src="img_url + '11EcWNPiOhHr8zo68FK5zpo_ZlxJmTKG1&sz=w0'" width="100%" height="100%">   
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialogimg = false"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
          <v-flex xs4>
                    <img :src="img_url + '11EcWNPiOhHr8zo68FK5zpo_ZlxJmTKG1&sz=w0'" 
                                width="100%" 
                                height="100%"
                                @click.stop="dialogimg1 = true"
                                > 
                                <v-dialog
                        v-model="dialogimg1"
                        max-width="85%"
                      >
                          <v-card v-model="dialogimg1">
                            <img :src="img_url + '11EcWNPiOhHr8zo68FK5zpo_ZlxJmTKG1&sz=w0'" width="100%" height="100%">   
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="dialogimg1 = false"
                            >
                              Tutup
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
          </v-flex>
          <v-flex xs4>
                    <img :src="img_url + '1yQuCH4ees6z_VZ3JnJkdBX2VCZvA3pV-&sz=w0'" 
                                width="100%" 
                                height="100%"
                                @click.stop="dialogimg2 = true"
                                > 
                                <v-dialog
                        v-model="dialogimg2"
                        max-width="85%"
                      >
                          <v-card v-model="dialogimg2">
                            <img :src="img_url + '1yQuCH4ees6z_VZ3JnJkdBX2VCZvA3pV-&sz=w0'" width="100%" height="100%">   
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="dialogimg2 = false"
                            >
                              Tutup
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
          </v-flex>
          <v-flex xs4>
                    <img :src="img_url + '1b2yQFsE7TM7GaxCU8NXMw0wlyB3kv1SU&sz=w0'" 
                                width="100%" 
                                height="100%"
                                @click.stop="dialogimg3 = true"
                                > 
                                <v-dialog
                        v-model="dialogimg3"
                        max-width="85%"
                      >
                          <v-card v-model="dialogimg3">
                            <img :src="img_url + '1b2yQFsE7TM7GaxCU8NXMw0wlyB3kv1SU&sz=w0'" width="100%" height="100%">   
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="dialogimg3 = false"
                            >
                              Tutup
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
          </v-flex>
            </v-layout>
            <p>
              <h2 class="text-center">PAKET BRONZE</h2>
                <br>
                <p class="text-justify">
                  Bagi kamu yang ingin memulai usaha tapi minim modal kami rekomendasikan untuk mengambil paket ini.
                  <br><span>Fitur Keunggulan :</span>
                  <ul>
                      <li>Gerobak Ekslusif</li>
                      <li>Seragam </li>
                      <li>Alat Pendukung</li>
                      <li>Media Promosi</li>
                      <li>Bahan Baku Awal</li>
                      <li>Kemasan</li>
                  </ul>   
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>

<v-tab-item
        value="tab-3"
      >
        <v-card flat>
          <v-card-text>
            <v-layout row wrap class="pa-3">
              <img :src="img_url + '11EcWNPiOhHr8zo68FK5zpo_ZlxJmTKG1&sz=w0'" 
              width="100%" 
              height="100%"
              @click.stop="dialogimg = true"
              > 
              <v-dialog
                v-model="dialogimg"
                max-width="85%"
              >
      <v-card v-model="dialogimg">
          <img :src="img_url + '11EcWNPiOhHr8zo68FK5zpo_ZlxJmTKG1&sz=w0'" width="100%" height="100%">   
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="green darken-1"
            text
            @click="dialogimg = false"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
          <v-flex xs4>
                    <img :src="img_url + '11EcWNPiOhHr8zo68FK5zpo_ZlxJmTKG1&sz=w0'" 
                                width="100%" 
                                height="100%"
                                @click.stop="dialogimg1 = true"
                                > 
                                <v-dialog
                        v-model="dialogimg1"
                        max-width="85%"
                      >
                          <v-card v-model="dialogimg1">
                            <img :src="img_url + '11EcWNPiOhHr8zo68FK5zpo_ZlxJmTKG1&sz=w0'" width="100%" height="100%">   
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="dialogimg1 = false"
                            >
                              Tutup
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
          </v-flex>
          <v-flex xs4>
                    <img :src="img_url + '1yQuCH4ees6z_VZ3JnJkdBX2VCZvA3pV-&sz=w0'" 
                                width="100%" 
                                height="100%"
                                @click.stop="dialogimg2 = true"
                                > 
                                <v-dialog
                        v-model="dialogimg2"
                        max-width="85%"
                      >
                          <v-card v-model="dialogimg2">
                            <img :src="img_url + '1yQuCH4ees6z_VZ3JnJkdBX2VCZvA3pV-&sz=w0'" width="100%" height="100%">   
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="dialogimg2 = false"
                            >
                              Tutup
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
          </v-flex>
          <v-flex xs4>
                    <img :src="img_url + '1b2yQFsE7TM7GaxCU8NXMw0wlyB3kv1SU&sz=w0'" 
                                width="100%" 
                                height="100%"
                                @click.stop="dialogimg3 = true"
                                > 
                                <v-dialog
                        v-model="dialogimg3"
                        max-width="85%"
                      >
                          <v-card v-model="dialogimg3">
                            <img :src="img_url + '1b2yQFsE7TM7GaxCU8NXMw0wlyB3kv1SU&sz=w0'" width="100%" height="100%">   
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="green darken-1"
                              text
                              @click="dialogimg3 = false"
                            >
                              Tutup
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
          </v-flex>
            </v-layout>
            <p>
              <h2 class="text-center">PAKET SILVER</h2>
                <br>
                <p class="text-justify">
                  Bagi kamu yang ingin memulai usaha tapi minim modal kami rekomendasikan untuk mengambil paket ini.
                  <br><span>Fitur Keunggulan :</span>
                  <ul>
                      <li>Gerobak Ekslusif</li>
                      <li>Seragam </li>
                      <li>Alat Pendukung</li>
                      <li>Media Promosi</li>
                      <li>Bahan Baku Awal</li>
                      <li>Kemasan</li>
                  </ul>   
            </p>
          </v-card-text>
        </v-card>
      </v-tab-item>
</v-tabs-items >
 <v-tabs  
        v-model="tab"
        show-arrows
        center-active
        horizontal 
        class="pa-4">
            <v-tab href="#tab-1">
              <img :src="img_url + '1VR3gAST2RYUbmRGqFim1Zgqyvx_LH-2c&sz=w170-rw'" width="40px" height="50px">
              PAKET TANPA BOOTH
            </v-tab>

             <v-tab href="#tab-2">
              <img :src="img_url + '11EcWNPiOhHr8zo68FK5zpo_ZlxJmTKG1&sz=w170-rw'" width="40px" height="50px">
              PAKET BRONZE
            </v-tab>

            <v-tab href="#tab-3">
              <img :src="img_url + '11EcWNPiOhHr8zo68FK5zpo_ZlxJmTKG1&sz=w170-rw'" width="40px" height="50px">
              PAKET SILVER
            </v-tab>            
      </v-tabs>
  </v-card>
</v-flex>
        <v-flex xs12 id="promo"><v-img :src="'https://drive.google.com/thumbnail?id=106x2zsyclWyvfx8pP6F7U3bm98h-yz2I&sz=w' + width  + '-rw'" alt="Responsive image"></v-img></v-flex>
        <v-flex xs12 id=""><v-img :src="'https://drive.google.com/thumbnail?id=1XWVcZRze6FWmwLFb2M-twXfxGz6mdAWl&sz=w' + width + '-rw'" alt="Responsive image"></v-img></v-flex>
        <v-flex xs12 id="paket"><v-img :src="'https://drive.google.com/thumbnail?id=1wRvoe-fN5O8DT69A2WMC2HBfJLLpF2tx&sz=w' + width + '-rw'" alt="Responsive image"></v-img></v-flex>
        <v-flex xs12 id=""><v-img :src="'https://drive.google.com/thumbnail?id=1iGnYWvuT-Lx6ZjbiQLSNUkk2ELxqyU1R&sz=w' + width + '-rw'" alt="Responsive image"></v-img></v-flex>
        <v-flex xs12 id="produk"><v-img :src="'https://drive.google.com/thumbnail?id=1aCFjwL6DytLIq0a027wRK5D6Jjz2HZxL&sz=w' + width + '-rw'" alt="Responsive image"></v-img></v-flex>
        <v-flex xs12 id=""><v-img :src="'https://drive.google.com/thumbnail?id=1yKe0XR5O0HX2PlhbGmzs1a8NKhIa7YtG&sz=w' + width + '-rw'" alt="Responsive image"></v-img></v-flex>
        <v-flex xs12 id="roi"><v-img :src="'https://drive.google.com/thumbnail?id=1JTwr4DRxUbDzyghjGMJCUC0bQujEFSud&sz=w' + width + '-rw'" alt="Responsive image"></v-img></v-flex>
        <v-flex xs12 id=""><v-img :src="'https://drive.google.com/thumbnail?id=14NLAWXSMoCLbp8j7cW-fqq0f0jpu50R-&sz=w' + width + '-rw'" alt="Responsive image"></v-img></v-flex>
        <v-flex xs12 id="faq" class="ml-3 mr-3">
            <template>
              <v-expansion-panels>
                <v-expansion-panel>
                </v-expansion-panel>
                <v-expansion-panel-header disable-icon-rotate>
                
                      <p class="font-weight-black text-center max-width=320">F.A.Q</p>
                      <template v-slot:actions>
                        <v-icon color="error">
                          mdi-message-alert
                        </v-icon>
                      </template>
                    </v-expansion-panel-header>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    APA ITU CHEESETEA?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="subtitle-2 text-justify">
                          <p class="text-justify">Cheesetea  adalah waralaba ayam goreng krispi (crispy fried chicken). Renyah ayam dari tepung bumbu racikan rahasia serta varian saus menjadi pembeda dan keunggulan Cheesetea Fried Chicken dari produk ayam goreng krispi lainnya. Ada 6 pilihan saus yang dikreasikan untuk melengkapi nikmatnya setiap gigitan renyah ayamnya: Saus Blackpepper, Saus Extra Pedas, Saus Tomat, Saus Pedas, Mayonnaise Original, dan Mayonnaise Pedas. Dengan konsep produk yang unik dan kemasan eye-catching, tentunya membuat produk Cheesetea Fried Chicken akan semakin diminati di pasaran.</p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    MENGAPA PRODUK CHEESETEA MENJADI SALAH SATU PRODUK UNGGULAN?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                        <p class="subtitle-2 text-justify">
                          <p class="font-weight-medium">
															Berikut mengapa produk <strong>Cheesetea</strong> menjadi salah satu produk unggulan dan sangat worth it untuk dijalankan?
														</p>
														<ul>
															<li>
																Bisnis Makanan adalah bisnis dengan potensi yang sangat besar.
															</li>
															<li>
																Masyarakat Indonesia sudah mulai membelanjakan uangnya untuk kebutuhan tersier.
															</li>
															<li>
																Produk Cheesetea Harga Pokok Produksi (HPP) nya kecil, Marginnya tinggi.
															</li>
															<li>Waktu BEP/balik modal yang relatif cepat.</li>
															<li>
																Produksi by order, sehingga meminimalisir rugi karena tidak laku.
															</li>
															<li>Repeat order/beli kembali sangat tinggi.</li>
															<li>Memiliki ceruk/target market yang sangat luas.</li>
															<li>
																Selain itu tentunya Cheesetea memiliki banyak variasi rasa
															</li>
														</ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header>
                    APA KELEBIHAN BISNIS FRANCHISE CHEESETEA ?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                        <p class="subtitle-2 text-justify">
                          <p class="font-weight-medium">Berikut mengapa produk Cheesetea menjadi salah satu produk unggulan dan sangat worth it untuk dijalankan?</p>
                          <p>
															Berikut adalah kelebihan investasi di <strong>Cheesetea</strong> :
														</p>
														<ul>
															<li>Inovasi baru Cheesetea dengan penyajian yang berbeda.</li>
															<li>Belum banyak kompetitor.</li>
															<li>Pangsa pasar yang terus bertumbuh.</li>
															<li>
																Bahan baku murah, mudah didapat, dan dipenuhi secara rutin.
															</li>
															<li>
																Jaminan akan adanya inovasi produk sehingga ini bukanlah bisnis sesaat saja. Selalu tampil menjadi inovation leader, not follower.
															</li>
															<li>
																Desain outlet yang eye-catching dan menarik konsumen.
															</li>
														</ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    FASILITAS APA SAJA YANG PERLU DISEDIAKAN CALON MITRA ?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="font-weight-medium text-justify">Lokasi sangat penting. Harus berada di tempat yang strategis (tidak harus mahal sewanya), dan juga jangan semata-mata kita menyewa lokasi karena murah. Luas tempat minimal 1×2 meter. Nb: Jika Delivery Service lokasi tidak harus strategis. Jika ada mitra yang sama dalam satu wilayah, jarak antar outlet minimal 2 KM.</p>
                        <p>Contoh: daerah kampus, komplek perumahan, pusat perbelanjaan, mall, dll.</p>
                        <ul>
                            <li>Tenaga Pelaksana. Tiap outlet membutuhkan 1-2 orang karyawan.</li>
                        </ul>
                        <p class="font-weight-medium">Kriteria karyawan :</p>
                        <ul>
                            <li>Idealnya karyawan tinggal tidak jauh dari lokasi, maksimal 1 kali naik kendaraan umum.</li>
                            <li>Pria/wanita usia minimal 17 tahun, amanah, pekerja keras, berpenampilan baik, dan sopan.</li>
                            <li>Memiliki sikap yang baik dalam melayani (ramah) dan mudah bergaul (supel).</li>
                            <li>Keinginan mau belajar yang tinggi.</li>
                        </ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    BAGAIMANA SUPPORTING FRANCHISOR (PUSAT) SERTA PERANAN FRANCHISE (PENERIMA) ?
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p class="subtitle-2 text-justify">
                          <p class="font-weight-medium">Supporting Franchisor (Pusat) :</p>
														<ul>
															<li>
																Menyediakan perlengkapan yang dibutuhkan dalam bisnis <strong>Cheesetea</strong>.
															</li>
															<li>Menyediakan stok bahan baku utama.</li>
															<li>
																Melakukan dan menginformasikan perubahan harga jual ke konsumen.
															</li>
															<li>Melakukan inovasi produk.</li>
															<li>Melakukan kegiatan promosi nasional.</li>
															<li>
															Peranan Franchisee (Penerima) :
																
																	<li>Mengajukan usulan lokasi.</li>
																	<li>
																		Melakukan pengawasan secara menyeluruh atas peralatan, disiplin karyawan dan kebersihan outlet.
																	</li>
																	<li>
																		Melakukan pembelian bahan baku utama agar kualitas rasa sesuai standar Cheesetea.
																	</li>
																	<li>Bertanggung jawab atas penjualan.</li>
																	<li>
																		Terus-menerus memberikan support kepada karyawan dalam tugas sehari-hari.
																	</li>
																	<li>Bertanggung jawab atas biaya sewa tempat/lokasi</li>
																	<li>Melakukan promosi minimal radius 2 km dari outlet.</li>
														</ul>
                  </v-expansion-panel-content>
                </v-expansion-panel>

              </v-expansion-panels>
            </template>
        </v-flex>
<v-flex xs12 id="kemitraan" class="pa-4">
            <div class="booking col-md-12 text-center" id="pesan">
                <v-dialog
                  v-model="dialog"
                  persistent
                  max-width="600px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      DAFTAR KEMITRAAN
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title>
                      <span class="headline">DATA DIRI CALON MITRA</span>
                    </v-card-title>
                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                          >
                            <v-text-field
                              v-model="namadepan"
                              label="Nama Depan *"
                              hint="Masukkan nama depan anda"
                              persistent-hint
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="6"
                            md="6"
                          >
                            <v-text-field
                              v-model="namabelakang"
                              label="Nama Belakang *"
                              hint="Masukkan nama belakang anda"
                              persistent-hint
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-text-field
                              v-model="alamat"
                              label="Alamat *"
                              hint="Masukkan alamat yang sesuai"
                              persistent-hint
                              v-validate="'required'"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                          >
                            <v-autocomplete
                              v-model="paketpilihan"
                              :items="['Paket Bronze', 'Paket Gold', 'Paket Silver', 'Paket Platinum', 'Paket Custom']"
                              label="PILIHAN PAKET"
                              hint="Pilian paket"
                              persistent-hint
                              multiple
                            ></v-autocomplete>
                          </v-col>
                        </v-row>
                      </v-container>
                      <small>*Kolom harus diisi</small>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="dialog = false"
                      >
                        Batal
                      </v-btn>
                      <v-btn
                        color="blue darken-1"
                        text
                        @click="get_data"
                      >
                        Kirim
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
        </div>
</v-flex>

        <v-flex xs12 class="pa-15">
            <v-btn  
            href="/whatsapp.php?" block icon-and-text dark color="green darken-4">
            <v-icon left>mdi-whatsapp</v-icon>
                CHAT ADMIN
        </v-btn>
        </v-flex>
        <v-flex xs12 class="pt-0">
          <h3 class="font-weight-black text-center max-width=320">
                PRODUK TERLARIS LAINNYA <v-icon>mdi-star</v-icon>
            </h3>
         <v-tabs
            centered
            centecyan darken-4
            color="cyan darken-4"
            text
          >
            <v-tabs-slider color="yellow"></v-tabs-slider>
        
            <v-tab href="#tab-1">
              TOP PRODUCT
            </v-tab>
        
            <v-tab href="#tab-2">
              MAKANAN
            </v-tab>
        
            <v-tab href="#tab-3">
              MINUMAN
            </v-tab>
        
            <v-tab-item
              value="tab-1"
            >
              <v-layout row wrap class="pa-3">
                  <v-flex xs4  class="pa-3">
                     <a v-bind:href="'http://cheeseteaco.com/'">
                         <v-img :src="'https://drive.google.com/thumbnail?id=19MyW4KWYAsGB08rnBc4oy8jKoD7S8_7H&sz=w' + width + '-rw'" alt="Responsive image"></v-img>
                    </a>
                  </v-flex>
                  <v-flex xs4 class="pa-3">
                    <a v-bind:href="'http://friedchickenbites.com/'">
                        <v-img :src="'https://drive.google.com/thumbnail?id=1IOevTNE5uoepZKF96hmLdNy5_pNLIgIK&sz=w' + width + '-rw'" alt="Responsive image"></v-img>
                    </a>
                  </v-flex>
                  
                  <!-- <v-flex xs4 class="pa-3">
                      <a v-bind:href="'https://magumaguchicken.com/'">
                         <v-img :src="'https://drive.google.com/thumbnail?id=1iTQj_Qjq40LwKRorVNr-zmmoCff85frh&sz=w' + width + '-rw'" alt="Responsive image"></v-img>
                    </a>
                  </v-flex> -->
                  <v-flex xs4 class="pa-3">
                  <a v-bind:href="'https://jogjaleker.co.id/'">
                         <v-img :src="'https://drive.google.com/thumbnail?id=121ePOiNAc8_eipl9aivENz1R4JNkjefv&sz=w' + width + '-rw'" alt="Responsive image"></v-img>
                    </a>
                  </v-flex>
                   <v-flex xs4 class="pa-3">
                      <a v-bind:href="'http://ayamkrispi.com/'">
                        <v-img :src="'https://drive.google.com/thumbnail?id=1mjklEOtaVksFzxyv1poSDhbd_D3ThKjs&sz=w' + width + '-rw'" alt="Responsive image"></v-img>
                    </a>
                  </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item
              value="tab-2"
            >
              <v-layout row wrap>
                  <v-flex xs4 class="pa-3">
                    <a v-bind:href="'http://friedchickenbites.com/'">
                        <v-img :src="'https://drive.google.com/thumbnail?id=1IOevTNE5uoepZKF96hmLdNy5_pNLIgIK&sz=w' + width + '-rw'" alt="Responsive image"></v-img>
                    </a>
                  </v-flex>
                  <v-flex xs4 class="pa-3">
                    <a v-bind:href="'https://jogjaleker.co.id/'">
                         <v-img :src="'https://drive.google.com/thumbnail?id=121ePOiNAc8_eipl9aivENz1R4JNkjefv&sz=w' + width + '-rw'" alt="Responsive image"></v-img>
                    </a>
                  </v-flex>
                  <v-flex xs4 class="pa-3">
                      <a v-bind:href="'http://ayamkrispi.com/'">
                        <v-img :src="'https://drive.google.com/thumbnail?id=1mjklEOtaVksFzxyv1poSDhbd_D3ThKjs&sz=w' + width + '-rw'" alt="Responsive image"></v-img>
                    </a>
                  </v-flex>
              </v-layout>
            </v-tab-item>
            <v-tab-item
              value="tab-3"
            >
              <v-layout row wrap>
                   <v-flex xs4  class="pa-3">
                     <a v-bind:href="'http://cheeseteaco.com/'">
                      <v-img :src="'https://drive.google.com/thumbnail?id=19MyW4KWYAsGB08rnBc4oy8jKoD7S8_7H&sz=w' + width + '-rw'" alt="Responsive image"></v-img>
                    </a>
                  </v-flex>
              </v-layout>
            </v-tab-item>
          </v-tabs>
        </v-flex>
    </v-layout>
    
    <v-flex xs12 id="alamat">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3953.319894876777!2d110.43195791477788!3d-7.755856494410125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x4cdb08b4e9e9ef30!2sJoker%20Jogja%20Leker!5e0!3m2!1sid!2sid!4v1598932973881!5m2!1sid!2sid" width="100%" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
    </v-flex>
    <v-layout row wrap>
        <v-flex xs12 class="mb-10">
         <v-footer padless >
            <v-col
              class="text-center"
              cols="12"
            >
              {{ new Date().getFullYear() }} — <strong>Joker Corporation</strong>
            </v-col>
        </v-footer>
        </v-flex>
     </v-layout>
     <div  class="px-10">
      <v-bottom-navigation
              dark
              fixed
              horizontal
              background-color="green darken-3"
              
            >
              <v-btn 
                href="/validasi.php?"
                color="cyan darken-4"
                text
              >
                <span>Chat Admin</span>
        
                <v-icon>mdi-whatsapp</v-icon>
              </v-btn>
            </v-bottom-navigation>
            </div>
      </v-container>
 <v-snackbar 
      v-model="snackbar"
      centered
      light
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Tutup
        </v-btn>
      </template>
 </v-snackbar>

  </v-app>
</template>

<script>
export default {
  name: 'App',

  components: {
  },

  data: () => ({
          drawer: false,
          img_url: 'https://drive.google.com/thumbnail?id=',
          loading: true,
          width: 0,
          tab: null,
          namadepan: '',
          snackbar: false,
          text: '',
          namabelakang: '',
          alamat: '',
          paketpilihan: '',
          dialog: false,
          dialogimg: false,
          dialogimg1: false,
          dialogimg2: false,
          dialogimg3: false,
          items1: [
            { image: '1BJj7U_hqDcUmAtC4s7gAZs9Gzu_9a1-P', id: 'none'},
            { image: '1prm7RReB3UyvcVDwWPQFq_T1xA447HCw', id: 'none'},
            { image: '1v75GxCADv2OwlIj1Nj8nWQZyegdjn7VD', id: 'none'},
          ],
          items2: [
            { text: 'beranda', id: '#beranda' },
            { text: 'promo', id: '#promo' },
            { text: 'Produk', id: '#produk' },
            { text: 'Paket', id: '#paket' },
            { text: 'Kemitraan', id: '#kemitraan' },
            { text: 'F.A.Q', id: '#faq' },
            { text: 'Balik Modal', id: '#roi' },
            { text: 'Alamat', id: '#alamat' },
          ],

        }),
        methods: {
            ukuran() {
                this.width = window.innerWidth * 0
            },
            skeleton() {
              setTimeout(() => (this.loading = false), 1000)  
            },
            get_data() {
              this.dialog = false
              if(this.namadepan != '' && this.namabelakang != '' && this.alamat != '' && this.paketpilihan != '') {
                window.open('/whatsapp.php?ref=<?php echo $ref; ?>namadepan=' + this.namadepan + '&namabelakang=' + this.namabelakang  + '&alamat=' + this.alamat + '&paketpilihan=' + this.paketpilihan, '_self')
              }else {
                this.text = 'Pastikan semua kolom sudah teriisi...'
                this.snackbar = true
              }
            },
        },
        mounted() {
          this.skeleton();
          this.ukuran();  
        }
};
</script>
